.social-icon svg {
    transform: scale(1.25);
}

[vaul-drawer] {
  touch-action: none;
  transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1);
}

[vaul-drawer][vaul-drawer-direction='bottom'] {
  transform: translate3d(0, 100%, 0);
}

[vaul-drawer][vaul-drawer-direction='top'] {
  transform: translate3d(0, -100%, 0);
}

[vaul-drawer][vaul-drawer-direction='left'] {
  transform: translate3d(-100%, 0, 0);
}

[vaul-drawer][vaul-drawer-direction='right'] {
  transform: translate3d(100%, 0, 0);
}

.vaul-dragging .vaul-scrollable [vault-drawer-direction='top'] {
  overflow-y: hidden !important;
}

.vaul-dragging .vaul-scrollable [vault-drawer-direction='bottom'] {
  overflow-y: hidden !important;
}

.vaul-dragging .vaul-scrollable [vault-drawer-direction='left'] {
  overflow-x: hidden !important;
}

.vaul-dragging .vaul-scrollable [vault-drawer-direction='right'] {
  overflow-x: hidden !important;
}

[vaul-drawer][vaul-drawer-visible='true'][vaul-drawer-direction='top'] {
  transform: translate3d(0, var(--snap-point-height, 0), 0);
}

[vaul-drawer][vaul-drawer-visible='true'][vaul-drawer-direction='bottom'] {
  transform: translate3d(0, var(--snap-point-height, 0), 0);
}

[vaul-drawer][vaul-drawer-visible='true'][vaul-drawer-direction='left'] {
  transform: translate3d(var(--snap-point-height, 0), 0, 0);
}

[vaul-drawer][vaul-drawer-visible='true'][vaul-drawer-direction='right'] {
  transform: translate3d(var(--snap-point-height, 0), 0, 0);
}

[vaul-overlay] {
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.32, 0.72, 0, 1);
}

[vaul-overlay][vaul-drawer-visible='true'] {
  opacity: 1;
}

[vaul-drawer]::after {
  content: '';
  position: absolute;
  background: inherit;
  background-color: inherit;
}

[vaul-drawer][vaul-drawer-direction='top']::after {
  top: initial;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 200%;
}

[vaul-drawer][vaul-drawer-direction='bottom']::after {
  top: 100%;
  bottom: initial;
  left: 0;
  right: 0;
  height: 200%;
}

[vaul-drawer][vaul-drawer-direction='left']::after {
  left: initial;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 200%;
}

[vaul-drawer][vaul-drawer-direction='right']::after {
  left: 100%;
  right: initial;
  top: 0;
  bottom: 0;
  width: 200%;
}

[vaul-handle] {
  /* opacity: 0.8; */
  touch-action: pan-y;
  cursor: grab;
}

/* [vaul-handle]:hover, */

[vaul-handle]:active {
  opacity: 1;
  cursor: grabbing;
}

[vaul-handle]:focus-visible {
  outline: none;
}

[vaul-handle-hitarea] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: max(100%, 2.75rem); /* 44px */
  height: max(100%, 2.75rem); /* 44px */
  touch-action: inherit;
}

[vaul-overlay][vaul-snap-points='true']:not([vaul-snap-points-overlay='true']):not([data-state='closed']) {
  opacity: 0;
}

[vaul-overlay][vaul-snap-points-overlay='true']:not([vaul-drawer-visible='false']) {
  opacity: 1;
}

/* This will allow us to not animate via animation, but still benefit from delaying unmount via Radix. */

@keyframes fake-animation {
  from {
  }
  to {
  }
}

@media (pointer: fine) {
  [vaul-handle-hitarea] {
    width: 100%;
    height: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  [vaul-drawer] {
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}


@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/ae7b7882e4812cfb-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__drukWide_2a62e8';
src: url(/_next/static/media/5441890ba324a8de-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__drukWide_Fallback_2a62e8';src: local("Arial");ascent-override: 66.06%;descent-override: 16.74%;line-gap-override: 1.88%;size-adjust: 154.10%
}.__className_2a62e8 {font-family: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}.__variable_2a62e8 {--font-druk-wide: '__drukWide_2a62e8', '__drukWide_Fallback_2a62e8'
}

@font-face {
font-family: '__inter_42b0b7';
src: url(/_next/static/media/e744c16fc4f8b964-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__inter_Fallback_42b0b7';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_42b0b7 {font-family: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}.__variable_42b0b7 {--font-inter: '__inter_42b0b7', '__inter_Fallback_42b0b7'
}

